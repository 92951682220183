<template>
    <div id="content" class="row primary-content">
        <div class="section">
            <div class="container-fluid">
                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
                <form method="POST" class="form">
                    <div class="row row-head lined">
                        <div class="col-xs-12">
                            <h5>Site Notification Settings</h5>

                        </div>
                    </div>


                    <div class="row lined">
                        <div class="col-xs-12 col-sm-6">
                            <label>Who should be notified on site activity?</label>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <input class="form-control" v-model="email" @keydown.enter.prevent="addEmail" @keydown.tab.prevent="addEmail" />
                            <div class="sm-label space" v-for="e, i in settings.notificationEmails">{{e}} <i class="fa fa-remove" @click="removeEmail(i)"></i></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12">
                            <a class="btn btn-dark" @click="save">
                                <i class="fa fa-check"></i>
                                &nbsp;&nbsp;Save Settings</a>
                                <span v-show="isLoading($options.name)" >
                                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                                </span>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import Dropdown from '../assets/Dropdown.vue';

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';


    export default {
        name: "NotificationSettings",
        data() {
            return {
                category: 'notifications',
                settings:{
                    notificationEmails: []
                },
                email: ''
            }
        },
        components:{
            Dropdown,
            Status,
            Loader
        },
        created(){
            this.fetchData();
        },
        computed:{
        },
        methods:{
            addEmail(evt){

                var email = evt.target.value;

                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(email)) return this.errorSet(this.$options.name, "You have entered an invalid email address");


                if(this.settings.notificationEmails.indexOf(email) < 0 ){
                    this.settings.notificationEmails.push(email);
                }

                this.email = '';

            },
            removeEmail(index){
                this.settings.notificationEmails.splice(index, 1);
                this.save();
            },
            fetchData(){
                api.get(this, api.SETTINGS_NOTIFICATIONS).then(r => {
                    this.settings = r.settings;
                    this.settings.notificationEmails = JSON.parse(r.settings.notificationEmails);
                });
            },
            save(){

                var re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
                if(re.test(this.email)){
                    this.settings.notificationEmails.push(this.email);
                    this.email = '';
                }


                var save = {
                    category: this.category,
                    data: {
                        notificationEmails: JSON.stringify(this.settings.notificationEmails)
                    }
                };


                api.post(this, api.SETTINGS_SAVE, save).then(r => {
                    this.fetchData();
                    this.successSet(this.$options.name, "Settings Updated");
                });
            }
        }
    }
</script>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
    .space{
        margin: 5px;
    }
</style>